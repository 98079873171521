import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var ThreeStar = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M155 241l-47 2-14-44c-2-3-5-5-7-5-3 0-6 2-8 5l-13 45H20c-3 0-6 2-7 6-2 4 0 6 3 9l37 26-13 43c-1 3 0 8 3 9 2 1 3 1 4 1 2 0 3 0 5-1l37-28 38 27c3 1 6 1 9 0 3-2 3-6 3-9l-15-44 38-27c3-2 3-6 3-9-5-3-6-6-10-6zm169 0l-46 2-15-44c-1-3-4-4-7-4s-6 1-7 6l-13 45-47 1c-3 0-5 2-7 6-1 4 0 6 3 9l38 26-13 45c-2 3 0 5 3 8 1 2 2 2 4 2 1 0 3 0 4-2l36-33 38 26c3 2 6 2 9 0 3-1 3-6 3-9l-15-43 38-28c3-1 3-5 3-8-3-2-6-5-9-5zm168 0l-46 2-15-44c-1-3-4-4-7-4s-6 1-7 6l-13 45-47 1c-3 0-5 2-7 6-1 4 0 6 3 9l38 26-13 45c-2 3 0 5 3 8 1 2 2 2 4 2 1 0 3 0 4-2l38-27 38 26c2 1 5 1 8 0 2-7 3-10 2-13l-15-44 38-27c3-2 3-6 3-9-3-3-6-6-9-6z",
			key: "k0"
		})
	);
});
ThreeStar.displayName = "ThreeStar";
export var ThreeStarDimensions = { height: 24, width: 24 };
