"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typography = exports.colors = void 0;
var colors = {
  primaryBlue: '#041B93',
  primaryGray: '#000',
  secondaryBlueLight: '#21A1FF',
  secondaryNavy: '#213246',
  secondaryRed: '#CB000E',
  secondaryGreen: '#5FA75F',
  secondaryBlue: '#0E5882',
  secondaryYellow: '#F7D05E',
  tertiaryBlue: '#0E5882',
  tertiaryBlueLight: '#21A1FF',
  tertiaryGreen: '#398939',
  grayDark: '#9BA4B6',
  disabledFormGray: '#EEF0F9',
  textGray: '#525B6D',
  grayRegular: '#BEC6D5',
  grayLight: '#F8F8FC',
  black: '#000',
  plain: '#fff'
};
exports.colors = colors;
var typography = {
  // fontUrl: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600',
  type: {
    primary: 'Ultramarine, sans-serif'
  },
  letterSpacing: '.02em',
  weight: {
    regular: '400',
    bold: '700',
    extrabold: '800',
    black: '900'
  },
  size: {
    small: '14',
    medium: '24',
    large: '40'
  }
};
exports.typography = typography;