import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Communication = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M438 203h-20c0-99-66-166-162-166S94 106 94 203H74C74 94 150 16 256 16s182 80 182 187zM129 372H82c-41 0-74-32-74-73v-33c0-41 33-74 74-74h47zM82 213c-29 0-53 24-53 53v33c0 28 24 53 53 53h27V213zm348 159h-47V192h47c41 0 74 33 74 74v33c0 41-33 73-74 73zm-27-20h27c29 0 53-25 53-53v-33c0-29-24-53-53-53h-27zM256 514c-33 0-59-27-59-60 0-32 26-59 59-59s59 27 59 59c0 33-26 60-59 60zm0-96c-20 0-39 16-39 38 0 23 16 39 39 39s39-16 39-39c0-22-19-38-39-38zm47 51v-21c103 0 115-84 115-86l20 2c2 0-14 105-135 105z",
			key: "k0"
		})
	);
});
Communication.displayName = "Communication";
export var CommunicationDimensions = { height: 24, width: 24 };
