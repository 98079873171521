"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BreadcrumbsItem = exports.Breadcrumbs = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2;
var Breadcrumbs = _styledComponents.default.ul(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  font-family: Open Sans, sans-serif;\n  color: ", ";\n  font-size: 13px;\n  font-weight: 700;\n  line-height: 15px;\n  margin: 0 0 20px;\n  padding: 0;\n  list-style: none;\n"])), function (props) {
  return props.theme.colors.primaryGray;
});
exports.Breadcrumbs = Breadcrumbs;
var BreadcrumbsItem = _styledComponents.default.li(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  &::after {\n    content: '>';\n    transform: scaleY(1.75);\n    display: inline-block;\n    font-size: 13px;\n    font-weight: 700;\n    color: ", ";\n    margin-left: 7px;\n    margin-right: 7px;\n  }\n\n  &:last-child {\n    &::after {\n      display: none;\n    }\n  }\n\n  a {\n    display: inline-block;\n    font-size: 14px;\n    line-height: 14px;\n    margin-top: 2px;\n    font-weight: 700;\n\n    &:hover {\n      opacity: 0.7;\n    }\n  }\n"])), function (props) {
  return props.theme.colors.tertiaryBlueLight;
});
exports.BreadcrumbsItem = BreadcrumbsItem;