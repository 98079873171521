import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var More = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M115 270c0 30-24 53-54 53-29 0-53-23-53-53 0-29 24-53 53-53 30 0 54 24 54 53zm192 0c0 30-24 53-53 53s-53-23-53-53c0-29 24-53 53-53s53 24 53 53zm193 0c0 30-24 53-54 53-29 0-53-23-53-53 0-29 24-53 53-53 30 0 54 24 54 53z",
			key: "k0"
		})
	);
});
More.displayName = "More";
export var MoreDimensions = { height: 24, width: 24 };
