"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaginationNav = exports.PaginationItem = exports.Pagination = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3;
var Pagination = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  text-align: center;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  font-weight: 600;\n"])));
exports.Pagination = Pagination;
var PaginationItem = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  text-align: center;\n  cursor: ", ";\n  height: 32px;\n  line-height: 32px;\n  font-size: 11px;\n  color: ", ";\n  background: ", ";\n  margin: 0px 2px;\n  width: 32px;\n  border: 1px solid\n    ", ";\n  ", "\n"])), function (props) {
  return props.disabled ? 'not-allowed' : 'pointer';
}, function (props) {
  if (props.selected === true) {
    return props.theme.colors.plain;
  } else if (props.disabled === true) {
    return props.theme.colors.grayRegular;
  } else {
    return props.theme.colors.secondaryBlueLight;
  }
}, function (props) {
  return props.selected ? props.theme.colors.secondaryBlueLight : props.theme.colors.plain;
}, function (props) {
  return props.selected ? props.theme.colors.secondaryBlueLight : props.theme.colors.grayRegular;
}, function (props) {
  if (props.ellipsis === true) {
    return 'border: none;width: auto;font-size: 13px;';
  }
});
exports.PaginationItem = PaginationItem;
var PaginationNav = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  text-align: center;\n  cursor: ", ";\n  height: 32px;\n  line-height: 32px;\n  font-size: 11px;\n  color: ", ";\n  margin: 0px 5px;\n"])), function (props) {
  return props.disabled ? 'not-allowed' : 'pointer';
}, function (props) {
  return props.disabled ? props.theme.colors.grayRegular : '#21b0e8';
});
exports.PaginationNav = PaginationNav;