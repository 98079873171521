import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var DownloadDocuments = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M319 379c-4-4-10-4-14 0l-39 37V282c0-6-4-10-10-10s-10 4-10 10v134l-37-37c-2-2-4-2-6-2s-6 0-8 2c-5 4-5 10 0 14l53 53c2 2 4 4 8 4s6-2 8-4l55-53c5-4 5-10 0-14zm74 30h-35c-6 0-10-4-10-10s4-10 10-10h35c51 0 88-33 88-78 0-37-24-70-57-76l-8-2v-8c-2-27-13-49-33-61-18-11-41-13-59-4l-9 4-4-9c-18-32-53-53-92-53-29 0-57 12-76 35-20 23-30 51-28 82v10h-11c-38 2-71 37-71 76 0 45 35 82 78 82h45c4 2 8 6 8 12s-4 10-10 10h-45c-54 0-99-47-99-102 0-47 35-88 82-96 0-33 12-64 35-88 25-27 57-41 92-41 43 0 82 20 105 57 22-8 49-4 69 8 25 15 39 39 43 70 39 12 68 51 68 94-2 55-49 98-111 98z",
			key: "k0"
		})
	);
});
DownloadDocuments.displayName = "DownloadDocuments";
export var DownloadDocumentsDimensions = { height: 24, width: 24 };
