import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var ArrowDown = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 532 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M532 143c0 6-2 10-6 14L280 403c-4 4-8 6-14 6s-10-2-14-6L6 157c-8-8-8-20 0-28s20-8 29 0l231 231 231-231c9-8 21-8 29 0 4 4 6 8 6 14z",
			key: "k0"
		})
	);
});
ArrowDown.displayName = "ArrowDown";
export var ArrowDownDimensions = { height: 24, width: 24 };
