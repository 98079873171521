"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Image = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
var Image = _styledComponents.default.img(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\twidth: ", "px;\n\theight: ", "px;\n"])), function (props) {
  return props.width;
}, function (props) {
  return props.height;
});
exports.Image = Image;