import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Program = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M375 360l-80-33c16-18 29-49 29-75 0-52-35-74-68-74s-68 22-68 74c0 28 11 57 29 75l-80 33c-8 4-14 12-14 21v41c0 12 10 20 22 20h222c10 0 20-8 22-20v-43c-2-7-8-15-14-19zm-10 64l-222-2v-41l82-35c4-2 8-6 11-12 2-7 0-15-5-19-14-14-24-41-24-63 0-39 24-54 47-54 22 0 47 15 47 54 0 22-10 49-25 63-4 4-6 10-6 17 0 4 4 10 11 12l84 35v45c2-2 2 0 0 0zm86-385H328V28c0-6-4-10-11-10H197c-7 0-11 4-11 10v13H61c-28 0-51 22-51 51v373c0 28 23 51 51 51h390c28 0 51-23 51-51V90c0-27-23-51-51-51zm-244 0l98-2v96l-43-33c-4-2-8-2-12 0l-43 33zm274 424c0 16-14 30-30 30H61c-16 0-30-14-30-30V90c0-17 14-31 30-31h125v94c0 4 2 9 7 9 4 2 8 2 10 0l53-41 53 41c2 2 4 2 6 2s4 0 4-2c5-2 7-7 7-9V59h123c16 0 30 14 30 31v373z",
			key: "k0"
		})
	);
});
Program.displayName = "Program";
export var ProgramDimensions = { height: 24, width: 24 };
