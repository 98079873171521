import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var NegotiationOverview1 = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M63 362c-14 0-24-8-28-20-10-25-17-56-17-82 0-74 37-144 99-184l-15-54 158 39-113 117-14-45c-37 31-57 76-57 125 0 24 4 43 14 63 4 8 4 17 0 25-2 6-8 12-16 14-4 2-6 2-11 2zm68-311l10 33-6 4c-59 39-96 102-96 172 0 24 6 51 16 76 2 4 6 6 8 6h5c4-2 4-2 6-4v-6c-13-21-17-46-17-72 0-62 29-115 78-150l12-8 13 37 63-66zm16 469L74 377l159 8-22 35c8 0 16 2 25 2 28 0 57-9 81-23 7-4 13-4 21-4 8 2 16 8 20 16 9 15 4 31-8 41-37 21-74 31-112 31-19 0-39-2-60-8zm-38-123l41 82 18-29 8 2c23 7 41 11 62 11 34 0 69-11 102-27 2-2 6-6 2-12-2-4-6-6-8-8h-4c-29 16-60 26-94 26-15 0-31-2-43-4l-17-2 21-33zm264 4l-13-162 39 19v-15c-2-22-8-45-18-65l-2-4c-19-35-51-62-94-76-17-6-25-22-19-39 4-8 10-14 17-18 6-2 14-2 20 0 86 24 148 102 154 194v47l47 23zm10-127l8 88 74-51-29-14v-6c0-2 0-7 2-11v-41c-6-82-61-153-139-176-2-2-4-2-6 0-6 2-8 4-8 8-2 5 2 11 6 13 47 16 84 47 106 86v4c13 22 19 45 21 72v49z",
			key: "k0"
		})
	);
});
NegotiationOverview1.displayName = "NegotiationOverview1";
export var NegotiationOverview1Dimensions = { height: 24, width: 24 };
