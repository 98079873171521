import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Reverse = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M27 311c-7 0-11-4-11-10v-6c0-95 78-172 172-172h187l-88-86c-4-4-4-11 0-15s10-4 14 0l105 105c2 2 4 4 4 6s-2 6-2 8L303 246c-4 4-10 4-14 0-4-5-4-11 0-15l86-88H188c-84 0-151 68-151 152v6c0 6-4 10-10 10zm190 201c-2 0-6 0-8-2L104 405c0-2-2-4-2-6s2-6 2-8l105-105c4-4 10-4 14 0 4 5 4 11 0 15l-86 88h187c84 0 151-68 151-152v-6c0-6 4-10 10-10 7 0 11 4 11 10v6c0 95-78 172-172 172H137l86 86c4 4 4 11 0 15 0 2-4 2-6 2z",
			key: "k0"
		})
	);
});
Reverse.displayName = "Reverse";
export var ReverseDimensions = { height: 24, width: 24 };
