import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Handshake = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M496 334s0-2-2-2L315 153l-6 13c-10 20-24 34-28 34-7 0-35-8-52-12 35-28 66-65 78-80h127l43 41c2 2 4 4 8 2 2 0 7-2 9-4 4-4 4-12 0-16l-50-47h-79c2-2 2-4 2-6s0-7-2-9l-35-43H143c-6 0-10 4-10 11 0 10 4 14 10 14h174l27 35h-51l-4 4s-43 57-88 88c-2 4-4 8-2 14 6 19 80 31 82 31 14 0 30-16 41-33l157 158c2 4 4 8 4 12 0 6-2 10-6 15-4 4-8 6-14 6-4 0-8-2-12-4-2-2-2-5-5-5l-98-102-16 16 102 103h2c2 4 4 8 4 12 0 6-2 10-6 15-8 8-22 8-31 0l-100-99-16 15 100 100c4 4 6 10 6 14 0 6-2 10-6 15-8 8-22 8-31 0l-26-27-6-6-50-49-16 16 49 49c4 4 6 10 6 15 0 6-2 10-6 14-8 8-22 8-31 0l-32-33 2-2c6-6 10-16 10-25 0-10-4-18-10-24-4-4-8-6-15-8l7-6c8-9 12-19 14-31 0-12-4-23-12-31-7-6-17-10-27-10v-6c0-11-4-23-12-29-11-10-27-14-41-10-2-8-6-17-13-23-14-14-45-14-59 0l-27 27-38-39c-5-4-13-4-17 0s-4 12 0 16l43 37-8 8c-8 9-12 19-12 29s4 23 12 29c8 8 18 12 31 12 0 10 4 23 12 29 8 8 20 12 31 12 0 12 4 23 12 31 10 10 25 14 39 10 0 8 4 14 10 20 14 15 37 15 49 0l2-2 33 33c8 8 19 12 31 12s22-4 31-12c4-4 8-12 10-18l6 6c16 16 45 16 61 0 9-8 13-21 13-33 12 0 24-4 32-12 9-8 13-19 13-31 12 2 24-2 32-12 21-12 21-35 7-53zM96 346l4-4 50-49c2-2 4-4 6-9l4-4c8-8 20-8 28 0 5 4 7 9 7 15s-2 10-5 12l-6 6-53 53-4 4c-8 7-18 5-25-2-4-4-6-8-6-14-2-2-2-4 0-8zm45 41l2-2 60-60c4-2 8-4 12-4 6 0 10 2 14 6 4 5 7 9 7 15s-3 10-7 16l-53 53c-12 9-26 9-33 2-8-8-8-18-2-26zm-4-115l-55 55s0 2-2 2c-8 5-19 3-23-4-4-4-6-8-6-14s2-10 6-14l49-49c5-5 9-7 15-7s10 2 14 7c4 4 6 8 6 14-2 4-2 6-4 10zm56 154l20-21c2-2 6-4 10-4s6 2 10 4c3 2 5 6 5 11 0 4-2 6-5 8l-20 20c-4 4-14 4-18 0-9-6-9-14-2-18zm294-281z",
			key: "k0"
		})
	);
});
Handshake.displayName = "Handshake";
export var HandshakeDimensions = { height: 24, width: 24 };
