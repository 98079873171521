import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Notifications = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M469 512H43c-18 0-33-15-33-31v-82c0-12 8-22 21-29l159-65c3 0 3-2 5-4 0 0 0-2-2-4-33-33-56-90-56-144 0-92 62-133 121-133s121 41 121 133c0 54-23 111-55 144-2 2-2 4-2 6 0 0 2 2 4 2l159 65c13 5 19 17 19 29v82c-4 16-19 31-35 31zM256 41c-49 0-100 35-100 112 0 50 20 101 49 129 8 9 10 17 8 25s-8 14-14 16L39 389c-4 2-6 6-6 10v80c0 6 6 12 12 12h424c6 0 12-6 12-10v-80c0-4-2-8-6-10l-160-66c-8-4-14-10-14-18-2-8 2-18 8-25 31-28 49-82 49-129-2-77-53-112-102-112z",
			key: "k0"
		})
	);
});
Notifications.displayName = "Notifications";
export var NotificationsDimensions = { height: 24, width: 24 };
