import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Home = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M430 512H319c-6 0-10-4-10-10V338H207v164c0 6-4 10-10 10H82c-6 0-10-4-10-10V268H20c-4 0-8-2-10-6s0-8 2-10L250 24c4-4 10-4 14 0l236 228c2 2 4 8 2 10-2 4-6 6-10 6h-52v234c0 6-6 10-10 10zm-102-21h90V258c0-6 4-10 10-10h37L256 45 47 248h35c6 0 10 4 10 10v233h92V327c0-6 4-10 11-10h122c7 0 11 4 11 10z",
			key: "k0"
		})
	);
});
Home.displayName = "Home";
export var HomeDimensions = { height: 24, width: 24 };
