import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var MailRead = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M20 512h-4c-2 0-2-2-4-2v-2-2-2-293c0-2 0-4 2-6l2-2L252 22c4-2 8-2 12 0l236 178 2 3c2 2 2 4 2 6v301c0 2-2 2-4 2h-4zm27-21h416L307 356l-45 33c-4 2-8 2-12 0l-45-33zm277-147l157 135V231zM31 231v248l157-135zm180 103l45 32 45-32 172-123L256 45 37 211z",
			key: "k0"
		})
	);
});
MailRead.displayName = "MailRead";
export var MailReadDimensions = { height: 24, width: 24 };
