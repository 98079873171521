import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Graduation = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M504 180s-2 0 0 0c-2-2-2-2-2-4L260 20c-4-2-8-2-10 0L12 174c-2 0-4 4-4 8s2 6 4 8l68 43v162c0 66 53 119 119 119h114c66 0 119-53 119-119V237l53-32v184c0 6 4 10 11 10 6 0 10-4 10-10V184c-2 0-2-2-2-4zm-92 215c0 53-43 98-99 98H197c-54 0-99-43-99-98V248l152 98c4 2 8 2 10 0l148-94v143zm4-172L258 325 96 221l-59-39L254 43l221 143z",
			key: "k0"
		})
	);
});
Graduation.displayName = "Graduation";
export var GraduationDimensions = { height: 24, width: 24 };
