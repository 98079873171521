import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var CheckmarkCircle = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M256 514C119 514 8 403 8 266S119 18 256 18s248 111 248 248-111 248-248 248zm0-475C131 39 29 141 29 266s102 227 227 227 227-102 227-227S381 39 256 39zm-53 350l-90-72c-4-4-7-10-2-14 4-4 10-4 14-2l74 59 186-213c4-4 10-4 14 0s4 10 0 15z",
			key: "k0"
		})
	);
});
CheckmarkCircle.displayName = "CheckmarkCircle";
export var CheckmarkCircleDimensions = { height: 24, width: 24 };
