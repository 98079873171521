import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var LocationMarker = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M256 512c-6 0-10-2-14-8L104 299c-4-6-8-15-12-21-22-49-22-104 0-151 21-43 55-78 101-94 45-17 94-15 137 6 43 20 78 55 94 100s14 94-6 137c-4 8-8 17-15 25L268 504c-2 6-6 8-12 8zm0-471c-18 0-37 4-55 10-41 14-72 45-90 84-21 43-21 92 0 135 4 6 6 12 10 19l135 200 133-200c4-7 8-15 12-23 19-39 19-82 5-123-15-41-46-72-84-90-21-8-43-12-66-12zm0 264c-57 0-104-47-104-105 0-57 47-104 104-104s104 47 104 104c0 58-47 105-104 105zm0-189c-47 0-84 37-84 84 0 48 37 84 84 84s84-36 84-84c0-47-37-84-84-84z",
			key: "k0"
		})
	);
});
LocationMarker.displayName = "LocationMarker";
export var LocationMarkerDimensions = { height: 24, width: 24 };
