import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Bulb = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M311 379H199c-11 0-21-4-27-13-6-6-10-16-10-26 0-29-10-58-27-80-45-62-37-148 21-201 28-24 63-39 102-39s74 15 102 39c58 51 66 137 21 199-19 22-29 51-29 80 0 10-4 20-10 26-10 11-20 15-31 15zM256 41c-33 0-63 12-88 35-49 45-57 118-18 172 20 26 30 59 30 92 0 4 2 10 6 12 4 4 9 6 13 6h112c4 0 11-2 13-6 4-4 6-8 6-12 0-33 12-66 32-92 39-54 31-127-18-172-25-23-55-35-88-35zm0 471c-12 0-27-4-39-10-22-13-37-39-39-66v-18c0-7 2-11 6-15s9-6 15-6h112c6 0 11 2 15 6 6 6 6 10 6 15v18c0 27-15 53-39 66-10 6-25 10-37 10zm-55-94v18c0 20 12 39 28 47 17 10 39 10 56 0 16-10 28-29 28-47v-18z",
			key: "k0"
		})
	);
});
Bulb.displayName = "Bulb";
export var BulbDimensions = { height: 24, width: 24 };
