import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Alert2 = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M492 469H20c-4 0-6-2-8-4s-2-6 0-11L248 45c4-6 14-6 16 0l236 409c2 2 2 7 0 11s-4 4-8 4zM37 448h438L256 69zm205-231c0-6 4-12 10-12h24c7 0 11 6 11 12l-11 92c0 6-6 10-12 10s-10-4-12-10zm20 119c12 0 21 8 21 20s-9 21-21 21-20-9-20-21c0-10 8-20 20-20z",
			key: "k0"
		})
	);
});
Alert2.displayName = "Alert2";
export var Alert2Dimensions = { height: 24, width: 24 };
