import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var DedicatedAccount = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M457 514H55c-28 0-51-23-51-51V69c0-28 23-51 51-51h400c28 0 51 23 51 51v394c2 28-23 51-49 51zM55 39c-16 0-30 14-30 30v394c0 16 14 30 30 30h400c16 0 30-14 30-30V69c0-16-14-30-30-30zm355 407H102c-14 0-26-12-26-24v-58c0-10 6-18 16-22l115-47c-25-25-41-68-41-107 0-67 47-98 90-98s90 31 90 98c0 39-16 82-41 107l115 49c10 4 16 12 16 22v58c-2 12-14 22-26 22zM256 106c-35 0-70 25-70 78 0 33 15 72 35 92 6 6 8 15 6 21s-6 12-12 14l-115 47c-2 2-4 2-4 4v58c0 2 2 4 6 4h308c4 0 6-2 6-6v-58c0-2 0-4-2-4l-117-47c-8-4-12-10-12-14-2-9 2-15 6-21 20-20 35-57 35-90 0-53-35-78-70-78z",
			key: "k0"
		})
	);
});
DedicatedAccount.displayName = "DedicatedAccount";
export var DedicatedAccountDimensions = { height: 24, width: 24 };
