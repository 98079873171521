import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var HowItWorks = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M406 510h-37l-9-28c-6-2-12-4-18-8l-25 14-24-25 14-24c-4-6-6-12-8-19l-29-8v-35l29-8c2-6 4-12 8-18l-14-25 24-24 25 14c6-4 12-6 18-8l9-29h34l9 29c6 2 12 4 18 8l25-14 24 24-14 25c4 6 6 12 8 18l29 8v35l-29 8c-2 7-4 13-8 19l14 24-24 25-25-14c-6 4-12 6-18 8zm-25-14h12l4-18c2-4 4-8 11-11 6-2 12-4 16-6s10-2 14 0l17 9 8-9-8-16c-2-4-2-8 0-12 4-6 6-13 8-17s4-8 8-10l18-4v-12l-18-6c-4-3-8-5-8-9-2-6-4-12-8-16-2-4-2-8 0-12l8-17-8-8-17 8c-4 2-10 2-12 0-6-4-12-6-16-8s-9-4-11-10l-4-19h-12l-4 19c-2 4-4 8-8 10-6 2-13 4-19 8-4 2-8 2-14 0l-16-8-9 8 9 17c2 4 2 8 0 12-5 6-7 12-9 16s-4 9-10 11l-18 6v12l18 4c4 2 8 4 10 10s4 13 9 17c2 4 2 8 0 12l-9 16 9 9 16-9c4-2 8-2 12 0 6 2 10 7 17 7 4 2 8 4 10 8zm49-22zm-88 0zm2 0zm88 0zm-133-56zm176 0zm-174-49zm172 0zm-43-53zm-88 0zm90 0zm-88 0zm43 121c-22 0-41-19-41-41 0-23 19-41 41-41 23 0 41 18 41 41 0 22-18 41-41 41zm0-68c-14 0-27 12-27 27 0 14 13 26 27 26s27-12 27-26c0-17-13-27-27-27zm-205-51h-45l-10-37c-8-2-16-6-25-10l-32 18-31-30 18-33c-4-8-8-17-10-25l-37-10v-45l37-10c2-8 6-17 10-25L39 78l31-30 32 16c9-4 17-8 25-10l10-37h45l11 37c8 2 16 6 24 10l33-18 31 30-19 33c4 8 8 16 10 25l37 10v45l-37 10c-2 8-6 17-10 25l19 33-31 30-33-18c-8 4-16 8-24 10zm-35-16h21l8-27c2-4 4-8 10-10 9-2 17-6 23-11 4-2 8-2 12 0l25 13 14-15-14-24c-2-4-2-8 0-12 4-9 8-17 10-23 2-4 4-8 10-10l27-8v-21l-27-8c-4-2-8-4-8-8-2-8-6-17-10-23-2-4-2-10 0-14l12-25-14-14-25 12c-4 2-8 2-12 0-8-4-16-8-25-10-4-2-8-4-10-8l-8-27h-21l-8 27c-2 4-4 8-10 8-8 2-16 6-25 10-4 2-8 2-12 0L72 64 57 78l13 25c2 4 2 8 0 12-4 8-9 17-11 23-2 4-4 8-10 10l-26 8v21l26 8c4 2 8 4 8 8 2 8 6 16 11 23 2 4 2 8 0 12l-15 24 15 15 24-13c4-2 8-2 12 0 9 5 17 9 25 11 4 2 8 4 10 8zm68-31zm-113 0zm-55-72zm225 0zM47 134zm225 0zM57 111zm205 0zm-47-47zm-113 0zm58 162c-33 0-60-27-60-60 0-32 27-59 60-59s59 27 59 59c0 33-26 60-59 60zm0-103c-25 0-43 21-43 43 0 25 20 43 43 43 24 0 43-20 43-43 0-22-21-43-43-43z",
			key: "k0"
		})
	);
});
HowItWorks.displayName = "HowItWorks";
export var HowItWorksDimensions = { height: 24, width: 24 };
