import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var CommunicationPref = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M139 514h-4c-4-2-6-4-6-8l-14-115H70c-31 0-58-23-58-51V69c0-28 27-51 58-51h374c31 0 58 23 58 51v271c0 28-27 51-58 51H268L145 512c-2 0-4 2-6 2zM70 39c-21 0-37 14-37 30v271c0 16 16 30 37 30h53c6 0 10 5 10 9l12 102 111-109c2-2 4-2 8-2h180c21 0 37-14 37-30V69c0-16-16-30-37-30zm153 280h-88c-6 0-10-4-10-10v-35H63c-6 0-10-4-10-10s4-10 10-10h62v-33c0-6 4-10 10-10h88c6 0 10 4 10 10v35h216c6 0 10 4 10 10s-4 10-10 10H233v33c0 6-4 10-10 10zm-78-20h68v-68h-68zm234-101h-88c-6 0-10-4-10-10v-35H66c-7 0-11-4-11-10s4-10 11-10h215v-33c0-6 4-10 10-10h88c6 0 10 4 10 10v33h62c6 0 10 4 10 10s-6 10-12 10h-62v35c2 6-4 10-8 10zm-78-20h68v-68h-68z",
			key: "k0"
		})
	);
});
CommunicationPref.displayName = "CommunicationPref";
export var CommunicationPrefDimensions = { height: 24, width: 24 };
