import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var NotificationsBell = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M256 98c-4 0-10 0-14-2-11-4-19-10-23-20-4-11-4-21 0-31s10-19 21-23c10-4 20-4 30 0 11 4 19 11 23 21s4 20 0 30c-4 11-10 19-21 23-6 0-10 2-16 2zm0-59c-2 0-6 0-8 2-4 2-8 6-10 10s-2 10 0 14 6 8 10 11c10 4 20-3 24-11s-2-20-10-24c-2-2-4-2-6-2zm154 417H102c-24 0-43-20-43-43 0-22 19-41 39-43V235c0-88 72-157 158-157 88 0 158 71 158 157v135c22 2 39 21 39 43 2 23-19 43-43 43zm-308-67c-12 0-22 10-22 22 0 13 10 23 22 23h308c12 0 22-10 22-23 0-12-10-22-22-22h-7c-6 0-10-4-10-10V235c0-75-61-137-137-137s-137 62-137 137v144c0 6-4 10-10 10zm154 125c-37 0-68-31-68-68h21c0 27 20 47 47 47s47-20 47-47h21c0 37-31 68-68 68z",
			key: "k0"
		})
	);
});
NotificationsBell.displayName = "NotificationsBell";
export var NotificationsBellDimensions = { height: 24, width: 24 };
