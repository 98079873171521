import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var MicrosoftWord = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M512 87v357c0 3-1 5-3 7s-5 3-8 3H319v-49h148v-23H318v-27h149v-23H318v-28h149v-22H318v-28h149v-22H318v-28h149v-22H318v-28h149v-22H318V76h183c3 0 6 1 8 4 2 2 3 4 3 7zM291 21v489L0 459V73l291-52zm-44 144l-36 2-24 147c-1-7-6-32-13-73l-14-70-34 1-14 69c-8 40-12 63-13 70h-1L77 176l-31 2 34 170 35 2 13-66c7-39 12-62 13-68h1c1 7 5 30 13 69l13 68 38 3 42-191z",
			key: "k0"
		})
	);
});
MicrosoftWord.displayName = "MicrosoftWord";
export var MicrosoftWordDimensions = { height: 24, width: 24 };
