import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var WelcomeCall = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M317 516c-6 0-12 0-18-2-41-6-84-35-139-88l-60-60c-53-53-82-96-90-137-10-43 6-86 51-131 11-10 19-16 25-18 12-4 20-2 31 8l55 69c10 17 10 25 8 33s-8 13-14 19c-19 18-27 37-25 57s17 39 27 49l43 43c10 10 29 25 49 27 19 2 39-8 55-27 7-6 11-12 19-14s16-2 28 4l74 55c10 10 15 21 10 33-2 8-8 16-16 27-39 34-76 53-113 53zM96 98h-2c-4 2-10 4-18 14-39 41-56 76-47 115 6 37 34 76 84 127l59 59c51 50 92 76 127 82 39 7 74-10 113-51 8-10 12-14 12-18s2-6-4-10l-72-54c-4-2-8-4-12-2-2 0-8 6-10 8-27 31-54 35-72 35-27-2-49-18-61-31l-43-43c-15-12-31-32-33-61 2-18 6-45 35-74 2-2 8-8 8-10s0-6-4-12l-54-70c-2-2-4-4-6-4zm398 174h-5l-4-4c-2-2-4-6-4-8 0-121-98-219-221-219h-4l-6-4-2-7v-2c0-6 6-12 12-12 135 0 244 109 244 244v4l-4 4c0 2-2 4-6 4zm-64 0h-4l-4-4c-2-2-4-6-4-8 0-86-70-158-158-158h-4l-6-4V86l6-8h6c100 0 180 82 180 180v4l-4 4c-2 4-4 6-8 6z",
			key: "k0"
		})
	);
});
WelcomeCall.displayName = "WelcomeCall";
export var WelcomeCallDimensions = { height: 24, width: 24 };
