import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var EnrolledDebt = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M68 137h241c6 0 10-4 10-10s-4-11-10-11H68c-7 0-11 5-11 11s4 10 11 10zm0 92h241c6 0 10-4 10-10s-4-10-10-10H68c-7 0-11 6-11 10s4 10 11 10zm251 82c0-6-4-10-10-10H68c-7 0-11 4-11 10s4 10 11 10h241c6 0 10-4 10-10zm84-10c-6 0-12 0-20 2V78c0-31-25-54-53-54H59C29 24 6 49 6 78v342c0 30 25 53 53 53h267c18 20 47 35 77 35 58 0 105-47 105-105 0-57-47-102-105-102zM59 454c-18 0-32-14-32-32V78c0-19 14-33 32-33h271c18 0 32 14 32 33v231c-36 16-63 53-63 96 0 19 4 35 12 49zm344 35c-20 0-38-8-53-18-6-4-12-10-16-17-10-14-17-30-17-49 0-30 19-59 43-73 7-5 13-7 21-9 6-2 12-2 20-2 48 0 84 37 84 84s-36 84-82 84zm46-94h-35v-35c0-6-4-10-11-10-6 0-10 4-10 10v35h-35c-6 0-10 4-10 10s4 11 10 11h35v34c0 6 4 11 10 11 7 0 11-5 11-11v-34h35c6 0 10-5 10-11s-6-10-10-10z",
			key: "k0"
		})
	);
});
EnrolledDebt.displayName = "EnrolledDebt";
export var EnrolledDebtDimensions = { height: 24, width: 24 };
