import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var WebsiteFeedback = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M463 155H352v-20c0-25-18-43-41-43H49c-22 0-41 18-41 43v129c0 25 19 43 41 43h10v59c0 4 2 9 7 11h4c2 0 6-2 8-5l61-65h23v20c0 25 18 43 41 43h170l63 68c2 2 4 4 8 4h5c4-2 6-6 6-10v-60h10c22 0 41-18 41-43V198c-2-24-21-43-43-43zm20 172c0 13-10 23-20 23h-21c-2 0-6 2-8 2-2 2-2 4-2 8v43l-47-51c-2-2-4-4-8-4H203c-13 0-21-10-21-23V198c0-12 11-22 21-22h260c12 0 20 10 20 22zm-356-38l-47 51v-43c0-2-2-6-2-8 0-3-4-3-8-3H49c-12 0-20-10-20-22V135c0-12 10-23 20-23h260c13 0 21 11 21 23v20H203c-23 0-41 19-41 43v88h-27c-4 0-6 0-8 3zm252-64l-62 61-32-32c-4-4-11-4-15 0s-4 10 0 14l47 47 76-76c4-4 4-10 0-14-2-4-10-4-14 0z",
			key: "k0"
		})
	);
});
WebsiteFeedback.displayName = "WebsiteFeedback";
export var WebsiteFeedbackDimensions = { height: 24, width: 24 };
