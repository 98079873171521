"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bodyStyles = exports.GlobalStyle = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js"));
var _styledComponents = require("styled-components");
var _templateObject, _templateObject2;
var bodyStyles = (0, _styledComponents.css)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 14px;\n\tfont-weight: 300;\n\tletter-spacing: ", ";\n\tcolor: ", ";\n"])), function (props) {
  return props.theme.typography.type.primary;
}, function (props) {
  return props.theme.typography.letterSpacing;
}, function (props) {
  return props.theme.colors.primaryGray;
});
exports.bodyStyles = bodyStyles;
var GlobalStyle = (0, _styledComponents.createGlobalStyle)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  :focus {\n    outline: none !important;\n  }\n  body {\n    ", "\n  }\n"])), bodyStyles);
exports.GlobalStyle = GlobalStyle;