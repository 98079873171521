import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Minus = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 516 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M278 291h218c12 0 20-9 20-21s-8-20-20-20H20c-12 0-20 8-20 20s8 21 20 21h217",
			key: "k0"
		})
	);
});
Minus.displayName = "Minus";
export var MinusDimensions = { height: 24, width: 24 };
