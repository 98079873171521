import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Flag = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M487 63c-75 41-149 21-227 0-76-22-151-43-229-8V33c0-7-4-11-11-11-6 0-10 4-10 11v464c0 7 4 11 10 11 7 0 11-4 11-11V338c73-39 145-19 223 4 45 12 92 26 139 26 35 0 70-6 105-26l6-2V53zm-6 262c-73 39-145 19-223-4-45-12-90-24-137-24-29 0-60 6-90 18V78c73-39 145-19 223 4 74 20 152 43 227 6z",
			key: "k0"
		})
	);
});
Flag.displayName = "Flag";
export var FlagDimensions = { height: 24, width: 24 };
