import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var ArrowRight = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M133 532c6 0 10-2 14-6l246-246c4-4 6-8 6-14s-2-10-6-14L147 6c-8-8-20-8-28 0s-8 20 0 29l231 231-231 231c-8 9-8 21 0 29 4 4 8 6 14 6z",
			key: "k0"
		})
	);
});
ArrowRight.displayName = "ArrowRight";
export var ArrowRightDimensions = { height: 24, width: 24 };
