import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Add = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 516 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M496 246H278V28c0-12-8-20-20-20s-20 8-20 20v218H20c-12 0-20 8-20 20s8 20 20 20h218v218c0 12 8 20 20 20s20-8 20-20V286h218c12 0 20-8 20-20s-8-20-20-20z",
			key: "k0"
		})
	);
});
Add.displayName = "Add";
export var AddDimensions = { height: 24, width: 24 };
