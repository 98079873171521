import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Like = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M106 495H53c-22 0-41-18-41-41V252c0-23 19-41 41-41h53c23 0 41 18 41 41v202c0 23-18 41-41 41zM53 231c-10 0-20 8-20 21v202c0 11 8 21 20 21h53c11 0 21-8 21-21V252c0-11-8-21-21-21zm377 264H197c-23 0-39-18-39-41V248c0-23 16-39 39-41 6-2 59-25 39-152 0-8 4-12 8-14 49-11 84-2 106 22 37 39 29 109 23 142l84 2c22 0 41 18 41 41l-27 208c0 21-18 39-41 39zM197 227c-11 0-19 8-19 21v206c0 13 8 21 19 21h233c10 0 21-10 21-21l26-208c0-11-8-19-20-19l-109-2 2-12c10-47 12-107-14-135-17-19-43-25-80-19 20 150-55 168-57 168z",
			key: "k0"
		})
	);
});
Like.displayName = "Like";
export var LikeDimensions = { height: 24, width: 24 };
