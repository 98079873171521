import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Menu = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M492 41H20c-6 0-10-6-10-11 0-4 4-10 10-10h472c6 0 10 4 10 10 0 7-4 11-10 11zm0 235H20c-6 0-10-4-10-10s4-10 10-10h472c6 0 10 4 10 10s-4 10-10 10zm0 236H20c-6 0-10-4-10-10 0-7 4-11 10-11h472c6 0 10 4 10 11 0 6-4 10-10 10z",
			key: "k0"
		})
	);
});
Menu.displayName = "Menu";
export var MenuDimensions = { height: 24, width: 24 };
