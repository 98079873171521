import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Dislike = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M147 78v202c0 23-18 41-41 41H53c-22 0-41-18-41-41V78c0-23 19-41 41-41h53c23 0 41 18 41 41zm-41 223c11 0 21-8 21-21V78c0-11-8-21-21-21H53c-10 0-20 8-20 21v202c0 11 8 21 20 21zM471 76l27 208c0 23-19 41-41 41l-84 2c6 33 14 103-23 142-22 24-57 33-106 22-6-2-8-6-8-14 20-127-33-150-39-152-23 0-39-18-39-41V78c0-23 18-41 39-41h233c23 0 41 18 41 39zM199 305s77 18 57 168c37 6 63 0 80-19 29-28 26-90 14-135l-2-12 111-2c10 0 20-8 20-19L451 78c0-11-11-21-21-21H197c-11 0-19 10-19 21v206c0 11 8 21 19 21z",
			key: "k0"
		})
	);
});
Dislike.displayName = "Dislike";
export var DislikeDimensions = { height: 24, width: 24 };
