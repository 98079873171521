import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var ContactInfo = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M420 407H186c-12 0-22-10-22-22v-43c0-8 6-17 14-21l84-35c-18-18-31-51-31-79 0-54 37-78 72-78s72 24 72 78c0 28-13 61-31 79l86 35c8 2 14 11 14 21v45c-2 10-12 20-24 20zM303 149c-22 0-51 15-51 58 0 24 10 53 27 67 6 6 8 12 6 19-2 6-6 10-11 12l-88 37v45s0 2 2 2h234c2 0 2-2 2-2v-43l-88-37c-8-4-10-10-10-14 0-7 2-13 6-17 14-14 26-43 26-67-4-45-30-60-55-60zm154 365H109c-27 0-50-23-50-49v-33H8v-82h51v-41H8v-82h51v-41H8v-82h51V67c0-26 23-49 50-49h348c26 0 49 23 49 49v398c-2 26-23 49-49 49zM80 432v33c0 16 12 28 29 28h348c16 0 28-12 28-28V67c0-16-12-28-28-28H109c-17 0-29 12-29 28v37h51v82H80v41h51v82H80v41h51v82zm-51-21h82v-41H29zm0-122h82v-41H29zm0-123h82v-41H29z",
			key: "k0"
		})
	);
});
ContactInfo.displayName = "ContactInfo";
export var ContactInfoDimensions = { height: 24, width: 24 };
