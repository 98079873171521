import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Search = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 521 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M510 514c-2 0-6 0-8-2L346 356c-35 39-88 64-145 64C90 420 0 329 0 219 0 108 90 18 201 18c110 0 200 90 200 201 0 45-16 88-41 123l158 157c4 5 4 11 0 15h-8zM201 39C100 39 20 121 20 219c0 100 82 180 181 180 98 0 180-82 180-180 0-100-80-180-180-180z",
			key: "k0"
		})
	);
});
Search.displayName = "Search";
export var SearchDimensions = { height: 24, width: 24 };
