import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var ClientServices = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M217 321V162c0-80 64-144 143-144 80 0 144 64 144 144 0 79-64 143-144 143-34 0-67-12-94-35zm49-80l8 9c23 22 56 36 86 36 68 0 123-55 123-122 0-68-55-123-123-123-67 0-122 55-122 123v108zm-30 273c-5 0-11 0-15-2-31-6-63-27-102-66l-45-45c-39-39-62-72-66-102-6-33 6-64 41-99 8-6 14-10 21-12 10-4 18 2 22 6l19 23c8 10 16 20 22 31 6 10 6 16 4 22s-6 10-10 14c-10 9-21 23-21 41 3 17 13 29 21 37l31 31c8 8 20 18 37 20 14 3 28-6 41-20 4-4 8-8 14-10s12 0 20 4l54 41c8 8 10 14 8 24-2 7-6 13-13 19-28 28-55 43-83 43zM76 209c-2 0-4 2-10 8-29 29-39 53-35 80 4 26 22 55 59 90l45 45c37 37 66 55 90 59 27 4 51-6 80-35 6-6 8-10 8-10l-2-2-51-39c-2 0-4-2-4-2l-4 4c-16 19-37 29-59 27-23-2-39-14-50-25l-30-30c-11-11-23-27-25-50-2-20 6-40 27-59 4-2 4-4 4-4s0-2-2-4c-6-8-15-19-23-29zm284 34c-45 0-81-36-81-81 0-46 36-82 81-82 46 0 82 36 82 82 0 45-36 81-82 81zm0-143c-34 0-61 27-61 62 0 34 27 61 61 61 35 0 62-27 62-61 0-35-27-62-62-62zm-18 62c0 6-5 12-12 12s-13-6-13-12c0-7 6-13 13-13s12 6 12 13zm31 0c0 6-6 12-13 12-6 0-12-6-12-12 0-7 6-13 12-13 7 0 13 6 13 13zm30 0c0 6-5 12-12 12s-12-6-12-12c0-7 5-13 12-13s12 6 12 13z",
			key: "k0"
		})
	);
});
ClientServices.displayName = "ClientServices";
export var ClientServicesDimensions = { height: 24, width: 24 };
