import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Excel = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M294 127h41v34h-41zm0 60h41v36h-41zM476 61H294v26h190v354H294v21h184c17 0 30-13 30-30V95c2-19-13-34-32-34zM294 249h41v32h-41zm0 60h41v36h-41zm0 62h41v32h-41zm67-62h85v36h-85zm0 62h85v32h-85zm0-184h85v36h-85zm0-60h85v34h-85zm0 122h85v32h-85zM0 63v397l294 51V10zm158 288l-30-70-28 64-40-4 45-88-45-89h42l26 61 30-64h45l-49 94 53 101z",
			key: "k0"
		})
	);
});
Excel.displayName = "Excel";
export var ExcelDimensions = { height: 24, width: 24 };
