import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var CheckmarkCircleSolid = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M256 28C125 28 18 135 18 266s107 238 238 238 238-107 238-238S387 28 256 28zm143 136L201 389l-88-72c-4-4-4-8-2-12 4-4 8-4 12-2l76 59 186-213c4-4 8-4 12 0 6 4 6 11 2 15z",
			key: "k0"
		})
	);
});
CheckmarkCircleSolid.displayName = "CheckmarkCircleSolid";
export var CheckmarkCircleSolidDimensions = { height: 24, width: 24 };
