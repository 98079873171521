import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var FirstSettlement = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M256 512c-104 0-188-84-188-189 0-51 20-100 57-135V20h268v174c33 35 51 80 51 129 0 103-84 189-188 189zm0-352c-90 0-162 73-162 161s74 162 162 162 162-74 162-162-72-161-162-161zM145 41v135c19-14 41-25 64-31V41zm170 104c21 6 39 17 58 31V41h-58zm-59-6c14 0 27 2 39 4V41h-66v100c11 0 19-2 27-2zm-70 287l15-78-58-55 78-13 35-71 35 71 78 13-58 55 13 78-70-37zm70-60l43 23-8-47 35-35-50-6-20-43-20 43-50 6 35 35-8 47z",
			key: "k0"
		})
	);
});
FirstSettlement.displayName = "FirstSettlement";
export var FirstSettlementDimensions = { height: 24, width: 24 };
