import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Video = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M451 444H61c-28 0-51-22-51-51V139c0-27 23-51 51-51h390c28 0 51 22 51 51v254c0 27-23 51-51 51zM61 108c-16 0-30 15-30 31v254c0 16 14 31 30 31h390c16 0 30-15 30-31V139c0-16-14-31-30-31zm146 248c-2 0-4 0-4-2-4-2-4-6-4-8V186c0-4 2-6 4-8 4-2 6-2 10 0l137 80c4 2 4 6 4 8s-2 6-4 8l-137 80c-2 2-4 2-6 2zm10-151v122l107-61z",
			key: "k0"
		})
	);
});
Video.displayName = "Video";
export var VideoDimensions = { height: 24, width: 24 };
