"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExternalContent = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var ExternalContent = _styledComponents.default.div.withConfig({
  displayName: "ExternalContent",
  componentId: "sc-6zq3vb-0"
})(["h1{font-size:24px;line-height:26px;color:", ";font-weight:400;margin-top:1em;margin-bottom:1.5em;}h2{font-weight:400;margin-top:1em;margin-bottom:1.5em;font-size:20x;line-height:22px;color:", ";}h3{font-weight:700;margin-top:1em;margin-bottom:1.5em;font-size:18px;line-height:1.5em;}h4{font-weight:700;margin-top:1em;margin-bottom:1.5em;font-size:16px;line-height:16px;}h5{font-weight:400;margin-top:1em;margin-bottom:1.5em;font-size:14px;line-height:14px;}h6{font-weight:400;margin-top:1em;margin-bottom:1.5em;font-size:11px;line-height:13px;}a{color:", ";text-decoration:underline;cursor:pointer;display:inline-block;&:hover{opacity:0.7;}}p{line-height:1.5em;font-weight:400;margin-top:1em;margin-bottom:1.5em;}blockquote{margin-left:40px;margin-top:1em;margin-bottom:1.5em;font-size:16px;line-height:20px;color:", ";}ul{font-size:14px;line-height:20px;margin-top:1em;margin-bottom:1.5em;padding-left:2em;color:", ";list-style-type:disc;& > li{display:list-item;font-size:14px;line-height:1.5em;font-weight:300;}}ol{font-size:14px;line-height:20px;margin-top:1em;margin-bottom:1.5em;padding-left:2em;color:", ";list-style-type:decimal;& > li{display:list-item;font-size:14px;line-height:1.5em;font-weight:300;}}table{margin-top:1em;margin-bottom:1.5em;border-collapse:collapse;tr{background-color:", ";border-top:1px solid ", ";}thead{tr{& > th{padding:8px 12px;font-weight:600;border:1px solid ", ";}}}tbody{tr{&:nth-child(2n){background-color:", ";}& > td{padding:8px 12px;border:1px solid ", ";box-sizing:border-box;}}}tfoot{tr{}}}"], function (props) {
  return props.theme.colors.primaryBlue;
}, function (props) {
  return props.theme.colors.primaryBlue;
}, function (props) {
  return props.theme.colors.secondaryBlueLight;
}, function (props) {
  return props.theme.colors.primaryGray;
}, function (props) {
  return props.theme.colors.primaryGray;
}, function (props) {
  return props.theme.colors.primaryGray;
}, function (props) {
  return props.theme.colors.plain;
}, function (props) {
  return props.theme.colors.grayRegular;
}, function (props) {
  return props.theme.colors.grayRegular;
}, function (props) {
  return props.theme.colors.grayLight;
}, function (props) {
  return props.theme.colors.grayRegular;
});
exports.ExternalContent = ExternalContent;