"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tooltip = exports.DarkGlassTooltip = void 0;
var _objectSpread2 = _interopRequireDefault(require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _polished = require("polished");
var _reactTooltip = _interopRequireDefault(require("react-tooltip"));
var Tooltip = (0, _styledComponents.default)(_reactTooltip.default).attrs(function (props) {
  return (0, _objectSpread2.default)({
    type: 'dark',
    scrollHide: true,
    effect: 'solid',
    delayHide: 1000,
    borderColor: props.theme.colors.secondaryGreen,
    backgroundColor: props.theme.colors.plain,
    clickable: true
  }, props);
}).withConfig({
  displayName: "Tooltip",
  componentId: "sc-1omk5ku-0"
})(["color:", " !important;background:", " !important;font-size:14px !important;border:2px solid ", " !important;padding:10px;border-radius:7px;max-width:300px;&.place-bottom{&::before{border-left:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-right:none !important;top:-8px;margin-left:-7px;}}&.place-top{&::before{border-right:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-left:none !important;margin-left:-6px;bottom:-7px;}}&.place-left{&::before{border-right:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-left:none !important;margin-top:-6px;right:-7px;}}&.place-right{&::before{border-left:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-right:none !important;margin-top:-6px;left:-7px;}}&::before{z-index:100;width:10px;height:10px;background:", ";transform:rotate(45deg);}"], function (props) {
  return props.theme.colors.primaryGray;
}, function (props) {
  return props.theme.colors.plain;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.plain;
});
exports.Tooltip = Tooltip;
var DarkGlassTooltip = (0, _styledComponents.default)(_reactTooltip.default).attrs(function (props) {
  return (0, _objectSpread2.default)({
    type: 'dark',
    scrollHide: true,
    effect: 'solid',
    offset: {
      top: -40,
      bottom: -40
    },
    delayHide: 1000,
    borderColor: props.theme.colors.secondaryNavy,
    backgroundColor: (0, _polished.rgba)(props.theme.colors.secondaryNavy, 0.95),
    clickable: true
  }, props);
}).withConfig({
  displayName: "Tooltip__DarkGlassTooltip",
  componentId: "sc-1omk5ku-1"
})(["color:", " !important;background:", " !important;font-size:14px !important;border:2px solid ", " !important;padding:10px;border-radius:7px;max-width:300px;&.place-bottom{&::before{border-left:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-right:none !important;top:-8px;margin-left:-7px;}}&.place-top{&::before{border-right:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-left:none !important;margin-left:-6px;bottom:-7px;}}&.place-left{&::before{border-right:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-left:none !important;margin-top:-6px;right:-7px;}}&.place-right{&::before{border-left:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-right:none !important;margin-top:-6px;left:-7px;}}&::before{z-index:100;width:10px;height:10px;background:", " !important;transform:rotate(45deg);}"], function (props) {
  return props.theme.colors.plain;
}, function (props) {
  return (0, _polished.rgba)(props.theme.colors.secondaryNavy, 0.95);
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return props.theme.colors.secondaryNavy;
}, function (props) {
  return (0, _polished.rgba)(props.theme.colors.secondaryNavy, 0.75);
});
exports.DarkGlassTooltip = DarkGlassTooltip;