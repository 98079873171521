import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var Close = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 532 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M295 266L526 35c8-9 8-21 0-29s-20-8-29 0L266 237 35 6C26-2 14-2 6 6s-8 20 0 29l231 231L6 497c-8 9-8 21 0 29 4 4 10 6 14 6s10-2 15-6l231-231 231 231c5 4 11 6 15 6s10-2 14-6c8-8 8-20 0-29z",
			key: "k0"
		})
	);
});
Close.displayName = "Close";
export var CloseDimensions = { height: 24, width: 24 };
