import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var DocumentVault = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M340 16H106c-32 0-59 27-59 60v380c0 33 27 60 59 60h300c32 0 59-27 59-60V141zm4 31l88 88h-88zm102 409c0 23-18 39-38 39H106c-22 0-38-18-38-39V76c0-23 18-39 38-39h218v118h122zm-84-223H150c-7 0-11-4-11-10s4-10 11-10h212c7 0 11 4 11 10s-4 10-11 10zm0 103H150c-7 0-11-4-11-11 0-6 4-10 11-10h212c7 0 11 4 11 10 0 7-4 11-11 11zm0 102H150c-7 0-11-4-11-10s4-10 11-10h212c7 0 11 4 11 10s-4 10-11 10z",
			key: "k0"
		})
	);
});
DocumentVault.displayName = "DocumentVault";
export var DocumentVaultDimensions = { height: 24, width: 24 };
