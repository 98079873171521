"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccordionItem = exports.AccordionHead = exports.AccordionBody = exports.Accordion = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var Accordion = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: block;\n"])));
exports.Accordion = Accordion;
var AccordionBody = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: block;\n\theight: auto;\n\tmax-height: 4000px;\n\tfont-size: 14px;\n\ttransition: all 300ms ease-in-out;\n"])));
exports.AccordionBody = AccordionBody;
var AccordionItem = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: block;\n\ttransition: max-height 300ms ease-in-out;\n\tpadding-left: 20px;\n\toverflow: ", ";\n\tmax-height: ", ";\n\n\t", " {\n\t\tline-height: ", ";\n\t\tcolor: ", ";\n\t\toverflow: ", ";\n\t\tvisibility: ", ";\n\t}\n"])), function (props) {
  return props.open ? "inherit" : "hidden";
}, function (props) {
  return props.open ? "4000px" : "44px";
}, AccordionBody, function (props) {
  return props.open ? 1.5 : 0;
}, function (props) {
  return props.open ? "#4a4a4a" : "transparent";
}, function (props) {
  return props.open ? "inherit" : "hidden";
}, function (props) {
  return props.open ? "visible" : "hidden";
});
exports.AccordionItem = AccordionItem;
var AccordionHead = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tcursor: pointer;\n\tcolor: ", ";\n\tfont-size: 14px;\n\tfont-weight: 600;\n\tmargin-bottom: 10px;\n"])), function (props) {
  return props.theme.colors.secondaryBlue;
});
exports.AccordionHead = AccordionHead;