import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var TakeAPhoto = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M467 467H45c-18 0-33-15-33-33V153c0-18 15-32 33-32h90l17-31c6-12 22-23 36-23h136c14 0 30 11 36 23l17 31h90c18 0 33 14 33 32v281c0 18-15 33-33 33zM45 141c-6 0-12 6-12 12v281c0 6 6 12 12 12h424c6 0 12-6 12-12V153c0-6-6-12-12-12H365l-21-43c-4-6-12-12-18-12H188c-6 0-16 6-18 12l-20 43zm211 264c-61 0-113-51-113-112 0-62 52-113 113-113s113 51 113 113c0 63-52 112-113 112zm0-202c-51 0-92 40-92 92 0 51 41 92 92 92s92-41 92-92c0-52-41-92-92-92z",
			key: "k0"
		})
	);
});
TakeAPhoto.displayName = "TakeAPhoto";
export var TakeAPhotoDimensions = { height: 24, width: 24 };
