"use strict";

var _interopRequireDefault = require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FooterSeparator = exports.FooterItem = exports.Footer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3;
var Footer = _styledComponents.default.footer(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tclear: both;\n\tcolor: ", ";\n\tfont-size: 12px;\n\tfont-family: Open Sans, sans-serif;\n\tpadding-top: 40px;\n\tpadding-bottom: 30px;\n\tpadding-left: 15px;\n\tpadding-right: 15px;\n\tbackground: ", ";\n"])), function (props) {
  return props.theme.colors.plain;
}, function (props) {
  return props.theme.colors.secondaryNavy;
});
exports.Footer = Footer;
var FooterItem = _styledComponents.default.span(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: ", ";\n\tline-height: ", ";\n\tfont-weight: 300;\n\n\tpadding-left: ", ";\n\tpadding-right: ", ";\n\tborder-left: ", ";\n\n\t&:first-child {\n\t\tpadding-left: 0;\n\t\tborder-left: none;\n\t}\n\n\ta {\n\t\tcolor: ", ";\n\t\tcursor: pointer;\n\t\tfont-size: 12px;\n\t}\n"])), function (props) {
  return props.inline ? "inline-block" : "block";
}, function (props) {
  return props.inline ? "15px" : "30px";
}, function (props) {
  return props.inline ? "15px" : "0px";
}, function (props) {
  return props.inline ? "15px" : "0px";
}, function (props) {
  return props.separator ? "1px solid #fff" : "none";
}, function (props) {
  return props.theme.colors.plain;
});
exports.FooterItem = FooterItem;
var FooterSeparator = _styledComponents.default.hr(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\topacity: 0.25;\n\tmargin-top: 20px;\n\tmargin-bottom: 20px;\n\theight: 0;\n\tborder: none;\n\tborder-bottom: 1px solid ", ";\n"])), function (props) {
  return props.theme.colors.grayRegular;
});
exports.FooterSeparator = FooterSeparator;