import { __assign } from "tslib";
import * as React from "react";
import { StyledIconBase } from "@styled-icons/styled-icon";
export var SignIn = React.forwardRef(function(props, ref) {
	var attrs = {
		fill: "currentColor",
		xmlns: "http://www.w3.org/2000/svg"
	};
	return React.createElement(
		StyledIconBase,
		__assign(
			{
				iconAttrs: attrs,
				iconVerticalAlign: "middle",
				iconViewBox: "0 0 512 554"
			},
			props,
			{ ref: ref }
		),
		React.createElement("path", {
			d:
				"M385 352l-72-78c-4-4-4-10 0-14l72-80c4-4 10-4 14 0s4 10 0 14l-67 74 65 72c4 4 4 10 0 14-2 2-4 2-6 2s-6-2-6-4zm-72-86c0-6 4-10 11-10h168c6 0 10 4 10 10s-4 10-10 10H324c-7 0-11-4-11-10zm-94 209c-115 0-209-94-209-209S104 57 219 57c62 0 121 27 160 76 4 4 2 10-2 14s-10 2-15-2c-36-43-88-67-143-67-104 0-188 84-188 188s84 188 188 188c55 0 109-24 143-67 5-4 11-4 15-2 4 4 4 10 2 14-39 47-98 76-160 76z",
			key: "k0"
		})
	);
});
SignIn.displayName = "SignIn";
export var SignInDimensions = { height: 24, width: 24 };
